import { trigger, transition, style, animate } from '@angular/animations';
import { ComponentPortal } from '@angular/cdk/portal';
import * as i0 from '@angular/core';
import { Injectable, Component, ChangeDetectionStrategy, ViewEncapsulation, HostBinding, InjectionToken, Injector, Inject, NgModule } from '@angular/core';
import { Subject, timer, NEVER } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import * as i1 from '@angular/cdk/overlay';
import { OverlayModule } from '@angular/cdk/overlay';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
function SnackbarComponent_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 2);
    i0.ɵɵlistener("click", function SnackbarComponent_button_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onAction());
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.ref.data.action, "\n");
  }
}
const DEFAULT_OPTIONS = {
  duration: 5000,
  vertical: 'bottom',
  horizontal: 'right'
};
const fadeInAnimation = trigger('fadeInAnimation', [transition(':enter', [style({
  opacity: 0
}), animate('150ms ease-in', style({
  opacity: 1
}))])]);
class SnackbarRef {
  constructor(_overlay) {
    this._overlay = _overlay;
    this._dismiss$ = new Subject();
  }
  dismissed$() {
    return this._dismiss$.asObservable();
  }
  dismissedByAction$() {
    return this._dismiss$.pipe(first(action => action !== undefined));
  }
  dismiss(action) {
    this._dismiss$.next(action);
    this._dismiss$.complete();
    this._overlay.dispose();
  }
  static {
    this.ɵfac = function SnackbarRef_Factory(ɵt) {
      return new (ɵt || SnackbarRef)(i0.ɵɵinject(i1.OverlayRef));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SnackbarRef,
      factory: SnackbarRef.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SnackbarRef, [{
    type: Injectable
  }], () => [{
    type: i1.OverlayRef
  }], null);
})();
class SnackbarComponent {
  constructor(ref) {
    this.ref = ref;
  }
  close() {
    this.ref.dismiss();
  }
  onAction() {
    this.ref.dismiss(this.ref.data.action);
  }
  get class() {
    return this.ref?.options?.addClass;
  }
  createTimer$() {
    if (this.ref?.options?.duration) {
      return timer(Number(this.ref?.options?.duration)).pipe(takeUntil(this.ref.dismissed$()));
    }
    return NEVER;
  }
  fadeInAnimationDone() {
    this.createTimer$().subscribe(() => this.close());
  }
  static {
    this.ɵfac = function SnackbarComponent_Factory(ɵt) {
      return new (ɵt || SnackbarComponent)(i0.ɵɵdirectiveInject(SnackbarRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SnackbarComponent,
      selectors: [["par-snackbar"]],
      hostAttrs: [1, "par-snackbar"],
      hostVars: 3,
      hostBindings: function SnackbarComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵsyntheticHostListener("@fadeInAnimation.done", function SnackbarComponent_animation_fadeInAnimation_done_HostBindingHandler() {
            return ctx.fadeInAnimationDone();
          });
        }
        if (rf & 2) {
          i0.ɵɵsyntheticHostProperty("@fadeInAnimation", undefined);
          i0.ɵɵclassMap(ctx.class);
        }
      },
      decls: 3,
      vars: 2,
      consts: [[1, "par-snackbar-message"], ["class", "par-snackbar-action", 3, "click", 4, "ngIf"], [1, "par-snackbar-action", 3, "click"]],
      template: function SnackbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, SnackbarComponent_button_2_Template, 2, 1, "button", 1);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(ctx.ref.data.message);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.ref.data.action);
        }
      },
      dependencies: [i2.NgIf],
      encapsulation: 2,
      data: {
        animation: [fadeInAnimation]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SnackbarComponent, [{
    type: Component,
    args: [{
      selector: 'par-snackbar',
      animations: [fadeInAnimation],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        'class': 'par-snackbar',
        '[@fadeInAnimation]': '',
        '(@fadeInAnimation.done)': 'fadeInAnimationDone()'
      },
      template: "<div class=\"par-snackbar-message\">{{ ref.data.message }}</div>\n<button class=\"par-snackbar-action\" *ngIf=\"ref.data.action\" (click)=\"onAction()\">\n  {{ ref.data.action }}\n</button>"
    }]
  }], () => [{
    type: SnackbarRef
  }], {
    class: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
const SNACKBAR_OPTIONS = new InjectionToken('snackbar.options');
class Snackbar {
  get snackbarRef() {
    return this._snackbarRef;
  }
  set snackbarRef(newRef) {
    this._snackbarRef = newRef;
  }
  //#endregion
  constructor(defaultOptions, _overlay, _injector) {
    this.defaultOptions = defaultOptions;
    this._overlay = _overlay;
    this._injector = _injector;
  }
  //#endregion
  //#region Implementierungen open(), clear()
  open(param1, param2, param3) {
    if (typeof param1 === 'string' && arguments.length === 1) {
      return this.openMessage(param1);
    }
    if (typeof param1 === 'object' && arguments.length === 1) {
      return this.openMessageOptions(param1);
    }
    if (typeof param1 === 'string' && typeof param2 === 'string' && arguments.length === 2) {
      return this.openMessageAndAction(param1, param2);
    }
    if (typeof param1 === 'string' && typeof param2 === 'object' && arguments.length === 2) {
      return this.openMessageAndOptions(param1, param2);
    }
    if (typeof param1 === 'object' && typeof param2 === 'object' && arguments.length === 2) {
      return this.openMessageOptionsAndOptions(param1, param2);
    }
    if (typeof param1 === 'string' && typeof param2 === 'string' && typeof param3 === 'object' && arguments.length === 3) {
      return this.openMessageAndActionAndOptions(param1, param2, param3);
    }
    throw new Error('Called Snackbar.open() with invalid arguments');
  }
  clear() {
    if (this.snackbarRef) {
      this.snackbarRef.dismiss();
    }
  }
  //#endregion
  //#region helper
  createOptions(options) {
    return {
      ...this.defaultOptions,
      ...options
    };
  }
  create({
    messageOptions,
    options
  }) {
    // If another Snackbar already exists, close it
    this.clear();
    const mergedOptions = this.createOptions(options);
    const positionStrategy = this.getPositionStrategy({
      horizontal: mergedOptions.horizontal,
      vertical: mergedOptions.vertical
    });
    const overlayRef = this.createOverlayRef(positionStrategy);
    this.snackbarRef = this.createSnackbarRef({
      overlayRef,
      messageOptions,
      options: mergedOptions
    });
    const injector = this.getInjector();
    this.createAndAttachSnackbarPortal({
      overlayRef,
      injector
    });
    return this.snackbarRef;
  }
  createOverlayRef(positionStrategy) {
    return this._overlay.create({
      positionStrategy
    });
  }
  createSnackbarRef({
    overlayRef,
    messageOptions,
    options
  }) {
    const snackbarRef = new SnackbarRef(overlayRef);
    snackbarRef.data = messageOptions;
    snackbarRef.options = options;
    return snackbarRef;
  }
  createAndAttachSnackbarPortal({
    overlayRef,
    injector
  }) {
    const snackbarPortal = new ComponentPortal(SnackbarComponent, null, injector);
    overlayRef.attach(snackbarPortal);
  }
  getInjector() {
    return Injector.create({
      parent: this._injector,
      providers: [{
        provide: SnackbarRef,
        useValue: this.snackbarRef
      }]
    });
  }
  getPositionStrategy({
    horizontal,
    vertical
  }) {
    const position = `${vertical}-${horizontal}`;
    switch (position) {
      case 'top-left':
        return this._overlay.position().global().top().left();
      case 'top-center':
        return this._overlay.position().global().top().centerHorizontally();
      case 'top-right':
        return this._overlay.position().global().top().right();
      case 'bottom-left':
        return this._overlay.position().global().bottom().left();
      case 'bottom-center':
        return this._overlay.position().global().bottom().centerHorizontally();
      case 'bottom-right':
        return this._overlay.position().global().bottom().right();
      default:
        return this._overlay.position().global().bottom().right();
    }
  }
  openMessage(message) {
    return this.create({
      messageOptions: {
        message
      }
    });
  }
  openMessageAndAction(message, action) {
    return this.create({
      messageOptions: {
        message,
        action
      }
    });
  }
  openMessageAndOptions(message, options) {
    return this.create({
      messageOptions: {
        message
      },
      options
    });
  }
  openMessageOptions(messageOptions) {
    return this.create({
      messageOptions
    });
  }
  openMessageOptionsAndOptions(messageOptions, options) {
    return this.create({
      messageOptions,
      options
    });
  }
  openMessageAndActionAndOptions(message, action, options) {
    return this.create({
      messageOptions: {
        message,
        action
      },
      options
    });
  }
  static {
    this.ɵfac = function Snackbar_Factory(ɵt) {
      return new (ɵt || Snackbar)(i0.ɵɵinject(SNACKBAR_OPTIONS), i0.ɵɵinject(i1.Overlay), i0.ɵɵinject(i0.Injector));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: Snackbar,
      factory: Snackbar.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Snackbar, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [SNACKBAR_OPTIONS]
    }]
  }, {
    type: i1.Overlay
  }, {
    type: i0.Injector
  }], null);
})();
class SnackbarModule {
  static forRoot(options) {
    if (options) {
      return {
        ngModule: SnackbarModule,
        providers: [{
          provide: SNACKBAR_OPTIONS,
          useValue: {
            ...DEFAULT_OPTIONS,
            ...options
          }
        }]
      };
    } else {
      return {
        ngModule: SnackbarModule,
        providers: [{
          provide: SNACKBAR_OPTIONS,
          useValue: DEFAULT_OPTIONS
        }]
      };
    }
  }
  static {
    this.ɵfac = function SnackbarModule_Factory(ɵt) {
      return new (ɵt || SnackbarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SnackbarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [Snackbar, {
        provide: SNACKBAR_OPTIONS,
        useValue: DEFAULT_OPTIONS
      }],
      imports: [CommonModule, OverlayModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SnackbarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, OverlayModule],
      exports: [SnackbarComponent],
      declarations: [SnackbarComponent],
      providers: [Snackbar, {
        provide: SNACKBAR_OPTIONS,
        useValue: DEFAULT_OPTIONS
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_OPTIONS, SNACKBAR_OPTIONS, Snackbar, SnackbarComponent, SnackbarModule, SnackbarRef, fadeInAnimation };
